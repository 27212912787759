<template lang="pug">
    aside#redes
        ul
            li(v-for="rede in filteredSettings")
                a(:href="rede.value")
                    .icon
                        FontAwesomeIcon(:icon="rede.icon")
</template>

<script>
import { faInstagram, faFacebookSquare, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

export default {
    name: 'component-redes',
    data() {
        return {
            redes: [
                {
                    icon: faInstagram,
                    name: 'instagram',
                },
                {
                    icon: faFacebookSquare,
                    name: 'facebook',
                },
                {
                    icon: faLinkedin,
                    name: 'linkedin',
                },
                {
                    icon: faYoutube,
                    name: 'youtube',
                },
            ],
        }
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    computed: {
        settings() {
            return this.$store.state.settings
        },
        filteredSettings() {
            const newLista = this.settings
                .filter(rede => this.redes
                .map(rede => rede.name)
                .includes(rede.attribute))
            return newLista.map(rede => ({
                ...rede,
                icon: this.redes.find(redeIcon => redeIcon.name === rede.attribute).icon
            }))
        }
    }
}
</script>

<style lang="stylus" scoped src="./Redes.styl"></style>