<template lang="pug">
    BannerGaleriaTemplate#bannerGaleria(:length="banners.length", :ativo.sync="ativo" :controls="false" :automatic="this.contents.content.length > 1")
        ul
            li(v-for="banner, index in banners", :class="{'ativo': ativo == index}")
                Link(v-if="banner.isLink" :contents='banner')
                    .video(v-if="banner.type == 'video' ")
                        video(:src="banner.banner", muted, autoplay, loop)
                        .thumb
                    img(:src="banner.banner", v-else-if="banner.type == 'image' ")
                div(v-else)
                    .video(v-if="banner.type == 'video' ")
                        video(:src="banner.banner", muted, autoplay, loop)
                        .thumb
                    img(:src="banner.banner", v-else-if="banner.type == 'image' ")
        Redes
</template>

<script>
import Redes from '@components/Redes/Redes'
import { props } from '@/mixins/component'
import Link from '@components/Link/Link'
import BannerGaleriaTemplate from '@components/BannerGaleriaTemplate/BannerGaleriaTemplate'

export default {
    name: "section-banner-galeria",
    props,
    components: {
        Redes,
        Link,
        BannerGaleriaTemplate
    },
    data() {
        return {
            ativo: 0,
        }
    },
    computed: {
        banners() {
            return this.contents.content.map(bannerItem => ({
                ...bannerItem,
                banner: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${bannerItem.banner}`
            }))
        },
    },
}
</script>

<style lang="stylus" scoped src="./HomeBannerSection.styl"></style>